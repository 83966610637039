.invoice-table thead.p-datatable-thead tr th {
    background: #188a42 !important;
    color: #fff !important;
}
.drk-green-bg {
    background: #188a42 !important;
}
.pdf-footer {
    margin-top: 50px;
    bottom: 0;
}
.pdf-container {
    .p-datatable .p-datatable-tbody > tr > td {
        font-family: sans-serif !important;
    }
    table,
    tr,
    th,
    td {
        font-family: sans-serif !important;
        span {
            font-family: sans-serif !important;
        }
    }
    span {
        font-family: sans-serif !important;
    }
}

#invoicePopUp {
    opacity: 0;
    max-height: 5px;
    overflow: auto;
}
