.headingsize{
    font-size: 16px;
}
.datasize{
    font-size: 12px !important;
}
.text-extrasmall{
    font-size: 10px !important;
}
.fw-normal{
    font-weight: 400 !important;
}
.teacher-upcoming .p-accordion-header-link {
    padding: 0 !important;
    min-height: 100px;
    align-items: inherit;
    text-decoration: none !important;
}
.teacher-upcoming .p-accordion-header-text{
    width: 100%;
}
.teacher-upcoming .p-accordion-content{
    padding: 0 !important;
}
.teacher-upcoming .p-accordion-tab{
    margin: 0 !important;
}
// .teacher-upcoming .p-accordion-tab-active .p-toggleable-content .p-accordion-content{
//     min-height: 120px;
//     height: 120px;
// }