.image-edit-btn,
.image-delete-btn {
    width: 48px;
    height: 48px;
    bottom: 10px;
    border: none
}

.image-edit-btn img,
.image-delete-btn img {
    width: 21px;
}

.image-edit-btn {
    left: 25%;
}

.image-delete-btn {
    left: 55%;
}

.form-label {
    top: -7px;
    left: 10px;
    padding: 0 5px;
}

.detail_tabs .p-tabview .p-tabview-nav {
    border: 0 !important;
}

.detail_tabs li.p-unselectable-text.p-tabview-selected.p-highlight div {
    background: $main;
}

.detail_tabs li.p-unselectable-text div {
    background: $gray-light;
    margin-left: 5px;
    border-radius: 0.5rem;
}

.assign-student .p-dialog-header {
    color: #fff !important;
}

.assign-student input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: $whitesmoke;
    position: absolute;
    right: 0;
    top: 0;
    background-size: 40%;
    height: 100%;
    background-position: center;
    width: 40px;
    cursor: pointer;
}

.assign-student input[type="time"] {
    position: relative;
}

.assign-student input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: $whitesmoke;
    position: absolute;
    right: 0;
    top: 0;
    background-size: 50%;
    height: 100%;
    background-position: center;
    width: 40px;
    cursor: pointer;
}
