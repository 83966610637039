.login {
    background: url(../../images/banner.png);
    position: relative;
    background-size: cover;
    background-position: center;
}

.login::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: -0;
}

input.p-inputtext.p-component.p-password-input {
    border: 0 !important;
    width: 100%;
}

input::placeholder {
    font-size: .85rem;
    font-family: 'open-sans';
}

.text-white-border {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
}

.login_tabs .p-dialog-header {
    background: #fff !important;
    
}
.login_tabs .p-dialog-header .p-dialog-title{
    font-size: 1rem !important;
    font-weight: 600 !important;
}
.login_tabs.p-dialog .p-dialog-header-icons {
    display: block !important;
}

.select-usericon {
    width: 105.93px;
    height: 105.93px;
    background: #FAFAFA;
    border: 2.26991px solid #59C6BC;
    filter: drop-shadow(0px 0.756637px 8.32301px rgba(0, 0, 0, 0.25))
}
.usericon {
    width: 105.93px;
    height: 105.93px;
    background: #FAFAFA;
    color: #9E9E9E !important;
}
.userheading{
    color: #9E9E9E !important;
}
button:focus{
    outline: none;
}
