// background-color
.bg-main {
    background: $main !important;
}
.bg-transparent {
    background: #ffffff00;
}
.bg-yellow {
    background: $yellow;
}
.bg-light-red {
    background: $light-red !important;
}
.bg-dark {
    background: $dark !important;
}
.bg-light-dark {
    background: $ligthdark;
}
.bg-light-green {
    background: $light-green;
}

.lightest-green {
    background: $lightest-green !important;
}

.bg-goldan {
    background: $goldan;
}
.bg-light-main {
    background: $light-main;
}
.bg-gray-light {
    background: $gray-light !important;
}
.bg-dark-gray {
    background: $graydark !important;
}
.bg-white-transparent {
    background: $whitetransparent;
}
.bg-whitesmoke {
    background: $whitesmoke !important;
}
.bg-skinlight-color{
    background: $skil-light-color;
}

// text-color

.text-dark {
    color: $dark !important;
}
.white-smoke {
    color: $whitesmoke !important;
}
.text-graylight {
    color: $gray-light;
}
.text-gray {
    color: $gray;
}
.text-red {
    color: $red !important;
}

.text-green {
    color: $green;
}

.text-dark-purple {
    color: $drkpurple;
}

.text-light-green {
    color: $light-green;
}

.text-main {
    color: $main !important;
}

.text-dark-gray {
    color: $graydark;
}
.text-blue {
    color: $blue !important;
}

// border
.border-light {
    border: 1px solid $main !important;
}
.main-content {
    height: 92vh;
    overflow: scroll;
}

// tablelist styling
.online-lesson thead.p-datatable-thead tr th,
.online-lesson thead.p-datatable-thead tr td,
.online-lesson thead.p-datatable-thead tr {
    background: $lightest-green !important;
}
