// .sliding-text{
//     dis
// }
// @media screen and (max-width:991px){
//     .sliding-text{
//         display: block;
//     }
// }
@media screen and (max-width:567px){
    .login-banner{
        min-height: 90vh !important;
    }
    .left-banner.enroll{
        height: 200px !important;
    }
    .logo-desktop{
        display: none;
    }
    .arrow-back.absolute{
        top: -20px !important;
    }
    .p-dialog {
        width: 90% !important;
    }
    .mobile-view.w-full{
        width: 95vw !important;
    }
    .school-detail-view .mobile-view.w-full{
        width: 85vw !important;
    }
}