.enroll-main-div {
    min-height: calc(100vh - 165.49px);
}

.instrument-box {
    width: 142px;
    height: 146px;
    border-radius: 10px;

    img {
        filter: grayscale(1);
        width: 52px;
        height: 52px;
    }
}

.instrument-view {
    .instrument-img {
        height: 334px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }
}

.background-overlay {
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

.more-info-dialog {
    .p-dialog-header {
        background: #fff !important;
    }

    .p-accordion-tab {
        svg {
            color: #000 !important;
        }
    }

    label {
        color: #000 !important;
        font-weight: 700;
    }
}

.react-tel-input .phone-input-number.form-control {
    height: 45px !important;
}