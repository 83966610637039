
.float-right {
    float: right;
  }
  .float-left {
    float: left;
  }
  .formbuilder button {
    border: none;
    padding: 8px;
    margin-right: 2px;
  }
  .blue {
    background-color: blue;
    color: white;
    border: none;
    padding: 8px;
  }
  .green {
    background-color: darkolivegreen;
    color: white;
  }
  .btn-toolbar {
    padding-bottom: 10px;
    /* margin: auto; */
  }

  .formarea {
    // border: 1px solid #c9c9c9;
    border-radius: 10px;
    padding: 5px;
}
.drag-and-drop-alert.alert.alert-info.no-drag {
  border: 1px dashed;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
}
.builder-components.drag-container.formio-builder-form, .formio-form, .formio-component-label-hidden, .drag-container.formio-builder-form:hover{
  height:100% !important;
  margin: 0;
  padding: 0 !important;
}
.card.form-builder-panel {
  border: 0;
  margin-bottom: 10px;
}
.card.form-builder-panel .form-builder-group-header {
  background: #59c6bc;
  border: 0;
  border-radius: 5px !important;
}
.card.form-builder-panel .card-body{
  padding: 0 !important;
}
.card.form-builder-panel .card-body .formcomponent.drag-copy {
  background: linear-gradient(45deg, #65cac0, #dcf3f1);
  border: none;
  color: #000;
  padding: 0.7rem;
}