$main: #59c6bc;
$light-main: rgb(89, 198, 188, 0.25);
$yellow: #f3e96e;
$dark: #363636;
$green: #15a78f;
$ligthdark: rgb(54, 54, 54, 0.1);
$light-green: rgba(89, 198, 188, 0.2);
$lightest-green: rgba(89, 198, 188, 0.3);
$goldan: #eead0e;
$graydark: #667085;
$drkpurple: #312e49;
$red: #e20000;
$light-red: rgba(214, 26, 3, 0.1);
$gray: #2e353b;
$blue: #0085ff;
$whitesmoke: #f5f5f5;
$gray-light: #e8e6e6;
$whitetransparent: rgba(255, 255, 255, 0.5);
$skil-light-color:#F8F8F8;
