.enrollform .p-accordion-content{
    padding: 0 !important;
}
.enrollform span.p-accordion-header-text{
    color: #000;
}
.enrollform a:hover{
 text-decoration: none;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
    box-shadow: none !important;
}