@import "./assets/sass/all.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");

* {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    // display: none;
}

body {
    font-family: "Manrope" !important;
}

.enrollform .p-accordion-content {
    border: 0 !important;
}

.open-sans,
.p-dropdown-empty-message,
.p-inputtext.p-component,
.main-menu .p-menuitem-link,
input {
    font-family: "Manrope" !important;
}
.p-dropdown-item {
    font-family: "Manrope" !important;
}

.p-card .p-card-content {
    padding: 0 !important;
}

.p-menu.p-menu-overlay {
    font-family: Manrope !important;
    font-size: 0.874rem;
    // top: 200px !important;
}
.student-chart-view.p-menu.p-menu-overlay {
    font-family: Manrope !important;
    font-size: 0.874rem;
    top: 128px !important;
}

.label-top {
    top: -8px;
}
// Carousel
input::placeholder {
    font-family: "Manrope" !important;
    font-size: 1rem;
}

.p-component {
    font-family: "Manrope" !important;
}

.p-carousel.p-component.p-carousel-horizontal {
    position: relative;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background: #e3f2fd;
    color: #495057;
    width: 1rem !important;
    height: 1rem !important;
    border-radius: 50% !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background-color: #2196f3;
    border: 3px solid #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    display: none;
}

// login
.dashboard-login input,
.dashboard-login .p-password-input {
    border-left: 0px !important;
    background: #fff !important;
}

// dashboard card
.white-smoke.dashboard_card {
    background: $whitesmoke;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 10px;
}
// time
input[type="time"].select_time_zone::-webkit-calendar-picker-indicator {
    line-height: 30px;
    transform: translateY(20px);
}
.select_time_zone {
    height: 40px;
}
.p-datatable .pi-sort-alt:before {
    content: "";
    background-image: url("./assets/icons/sortable.png") !important;
    height: 15px;
    width: 15px;
    position: absolute;
    background-repeat: no-repeat;
    top: 40%;
    background-size: contain;
}

.calender_picker .p-button {
    background: none !important;
    border: 0;
}
.primary-btn {
    height: 42px;
    font-size: 16px !important;
}
.input-password {
    border-left: none;
}
.calender_picker input {
    border: 0;
    padding: 12px;
}

.calender_picker::after {
    content: "";
    position: absolute;
    background: url(./assets/icons/uim_calender.png);
    background-size: contain;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 12px;
}

.calender_picker.p-calendar.p-component.p-inputwrapper.w-full.border-round-lg.overflow-hidden.border-1.border-400.p-calendar-w-btn.p-calendar-w-btn-right {
    flex-direction: row-reverse;
}

@media screen and (max-width: 567px) {
    .p-8.padding-none {
        padding: 1rem !important;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0px !important;
}
.radio-btn {
    .p-radiobutton-box.p-highlight {
        background: #fff !important;
    }
    .p-radiobutton-icon {
        background: $main !important;
    }
}
// .p-button {
//   color: #000000 !important;
//   font-family: "Manrope" !important;
//   padding: 0.5rem 1rem;
//   font-size: 1rem;
//   transition: background-color 0.2s, color 0.2s, border-color 0.2s,
//     box-shadow 0.2s;
// }

.form-label {
    top: -9px;
    left: 10px;
    padding: 0 5px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    height: 52px !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    height: 54px !important;
}

textarea:focus,
input:focus,
.p-inputtext:enabled:focus,
.p-dropdown:focus,
button:focus {
    outline: none;
    box-shadow: none !important;
}

.calendar-box input {
    background: none;
    border-color: #616161;
    border-radius: 0.5rem;
}
.calendar-box .calender-labeling {
    top: 12px;
}
.upcoming-event .p-accordion-content {
    border: 0 !important;
}
.p-disabled,
.p-component:disabled {
    opacity: 1 !important;
}
.bg-white {
    background: #fff !important;
}
.p-accordion.upcoming-event .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    position: absolute;
    right: 10px;
    top: 15px;
    transform: rotate(90deg);
}
.p-accordion.upcoming-event .p-accordion-tab.p-accordion-tab-active .p-accordion-header.p-highlight .p-accordion-toggle-icon {
    transform: rotate(180deg) !important;
}
.radius-max {
    border-radius: 15px 0px !important;
}
.p-dropdown .p-dropdown-label {
    color: #495057bf !important;
}
.programme-selected {
    border: 1px solid $main;
}
.dropdown-view,
.dropdown-view:focus {
    border: none !important;
    border-color: none !important;
    box-shadow: none !important;
}

.video-card {
    height: 180px;
    border-radius: 10px;
    overflow: hidden;
    justify-content: center;
    position: relative;
}
.video-card video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.playIcon {
    position: absolute;
    top: 39%;
    left: 42%;
}
.deleteVideo {
    position: absolute;
    top: 4%;
    right: 3%;
}

// input number
.react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 300px;
    outline: none;
}
.card-svg {
    top: 22px;
    right: 20px;
    width: 40px;
}
.p-inputtext {
    padding: 0.55rem 0.75rem !important;
}
.card-header {
    width: 100%;
    height: 250px;
}
.search-intrument.p-overlaypanel:after,
.search-intrument.p-overlaypanel:before {
    display: none;
}
.search-intrument .p-overlaypanel-content {
    padding: 0 !important;
}
.p-inplace .p-inplace-display {
    padding: 0.18rem 0.25rem !important;
    border-radius: 6px !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s !important;
}
.p-inplace .p-inplace-display {
    display: inline !important;
    cursor: pointer !important;
    font-size: 12px !important;
    color: #14b8a6;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #14b8a6 !important;
    color: #ffffff !important;
}
.text-decoration {
    text-decoration: none !important;
    color: black;
}

.login-custom-popup {
    animation: none !important;
    background-color: #ecfcfa !important;
    opacity: 0.9;
}
.school-card-mobile {
    display: none;
}
.school-card-pc {
    display: block;
}
.schedule-table-container {
    overflow-y: auto;
    .schedule-table {
        border: 1px solid #dddddd;

        td,
        th {
            padding: 15px 5px;
            text-align: center;
        }
        th {
            min-width: 110px;
        }
    }
}

@media only screen and (max-width: 480px) {
    .school-card-pc {
        display: none;
    }
    .school-card-mobile {
        display: block;
    }
}
* {
    scrollbar-width: thin;
    scrollbar-color: #eee #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: #fff;
}

*::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 20px;
}

/* Works on Chrome, Edge, and Safari */
.scrollable-card::-webkit-scrollbar {
    width: 8px;
}

.scrollable-card::-webkit-scrollbar-track {
    background: #eee;
}

.scrollable-card::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 20px;
}
.fc-event {
    background-color: #59c6bbe1;
    border-color: #59c6bc;
}
