.enroll {
  background: url(../../images/enroll.png);
  position: relative;
  background-size: cover;
  background-position: center;
}
.enroll::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: -0;
}

.enrollBtn.active {
  background: $yellow !important;
}

// student form
.student_banner {
  background: url(../../../assets/images/student-banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5%;
}
.student_banner::before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.form-accordion span.p-accordion-toggle-icon.pi.pi-chevron-down,
.p-accordion
  .p-accordion-header
  .p-accordion-header-link
  .p-accordion-toggle-icon {
  position: absolute;
  right: 10px;
  color: #fff;
  
}
.form-accordion .p-accordion-header.p-highlight a,
.p-accordion .p-accordion-header .p-accordion-header-link {
  background: $main !important;
  color: #fff !important;
  border-radius: 0.5rem !important;
  margin-bottom: 20px;
}
.form-accordion .p-accordion-content {
  border: none !important;
}
// input.form-box,
// .form-box input {
//   padding: 0.625rem !important;
// }
.header-none .p-dialog-header{
  display: none;
}
.calendar-date input{
  border: none;
  background: transparent;
}
.calender-labeling{
  right: 10px;
}

.float-right {
  float: right;
}
.confirmation-next{
  width: 30vw;
}
.confirmation-next .p-dialog-header{
  background: #fff !important;
  text-align: left;
}