.main-menu .p-menubar-root-list li a span {
    font-weight: 600;
}
.news-overlay {
    background: linear-gradient(359.28deg, #59c6bc 0.65%, rgba(217, 217, 217, 0) 99.41%);
    transform: translateX(-100%);
    transition: 0.5s ease-in-out;
}
.news-img:hover .news-overlay {
    transform: translateX(0);
}
.main-menu .p-menubar-end {
    margin: 0 !important;
}
.main-menu .p-menuitem-link:hover {
    // padding: 0 !important;
    background: none !important;
}
.heading {
    font-size: 5rem;
}

.cover-img {
    object-fit: cover;
}
.border-set {
    border: 1px solid rgba(224, 224, 224, 0.5) !important;
}

.left-banner {
    padding: 6.25rem !important;
}

.right-banner img {
    margin-left: -30px;
    margin-top: 20px;
    z-index: 9;
}

.spacer {
    height: 5rem;
}

.text-border {
    color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}
.music-img {
    bottom: -7rem !important;
    right: 2rem;
}
// .program-info-card:nth-child(odd){
//     margin-top: 50px;
// }
.programs-card .p-card-body {
    height: 100%;
}
.programs-card .p-card-content {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.program-info-card a {
    color: #000;
}
.program-info-card .p-card:hover {
    background: $lightest-green;
}
.program-info-card .p-card:hover a {
    color: $main;
    font-weight: 700 !important;
}

// benifits
.benifit-img {
    height: 49.75rem;
}
.benifit-img img {
    border-radius: 0 15rem 0 15rem;
    height: 100%;
}
.benifit-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: $main;
    z-index: -1;
    top: -25px;
    left: 25px;
    border-radius: 0 15rem 0 15rem;
}

// instrument
.instrument-card {
    min-height: 12rem;
}
.instrument-card:hover {
    box-shadow: 0px 4px 25px rgba(89, 198, 188, 0.25);
    color: $main;
}

// instrument and accessories
.accessories {
    background: url("../../images/bg-accessaries.png");
    background-position: center;
    background-size: cover;
    position: relative;
}
.accessories:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

// client
.client-img {
    height: 22rem;
}
// teacher
.ellipse {
    top: 35%;
}
// news
.news-description {
    background: rgba(159, 159, 159, 0.08);
}
.news-img {
    height: 15rem;
}
.news-para {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;
}

// review
// .reviews-image{
//     width: 40%;
// }
// .reviews-para{
//     width: 60%;
// }
// .reviews-image::before {
//     content: '';
//     position: absolute;
//     width: 65%;
//     height:80px;
//     background: $dark;
//     left: 17%;
//     top: 0;
//     z-index: -1;
//     border-radius: 20px 20px 0px 0px;
// }
// .reviews-image::after {
//     content: '';
//     position: absolute;
//     width: 65%;
//     height:60px;
//     background: $dark;
//     left: 17%;
//     bottom: 0;
//     z-index: -1;
//     border-radius: 0px 0px 20px 20px;
// }
// .reviews-image{
//     position: relative;

// }
// .reviews-para::before {
//     content: '';
//     width: 50%;
//     height: 7px;
//     position: absolute;
//     background: #ace2dd;
//     left: -45px;
//     top: -45px;
// }
.avatar_client {
    width: 46px;
    height: 46px;
}
.p-carousel-items-container {
    align-items: center;
    justify-content: space-between;
}
.p-carousel-item-active {
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin: 5px;
    width: 30% !important;
    padding: 20px 0;
    height: fit-content;
}
.p-carousel-item-start,
.p-carousel-item-end {
    background: $whitesmoke;
    box-shadow: none;
    padding: 0;
}
button {
    transition: 0.1s ease-in-out;
}
button:hover {
    box-shadow: 1px 3px 10px rgb(187, 187, 187);
}
@media screen and (max-width: 567px) {
    .left-banner {
        padding: 1.25rem !important;
    }
}
