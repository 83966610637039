.avtar {
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #000;
    line-height: 18px;
}

.password_feild input {
    background: transparent;
}

.password_feild input::placeholder {
    font-weight: 600;
    font-size: 12px;
}
.p-tabview.account-invoice .p-tabview-nav .p-tabview-ink-bar{
    display: none;
}
.account-invoice .p-tabview-panels{
    padding: 0 !important;
}
.account-invoice .p-tabview-nav-container{
    margin-bottom: 10px;
}
.side_menuitem.active {
    background: linear-gradient(90.16deg, #59C6BC -0.84%, rgba(89, 198, 188, 0) 98.91%);
    border-left: 3px solid #363636;
    font-weight: 700;
}

thead.p-datatable-thead tr th {
    background: $main !important;
}

.p-datatable-table tr th,
.p-datatable-table tr td {
    font-family: 'Manrope' !important;
}

.p-dialog-header {
    background: $main !important;
    padding: 1rem !important;
    color: #000 !important;
    font-family: 'Manrope' !important;
    text-align: center;
}

// .p-dialog .p-dialog-header-icons {
//     display: none !important;
// }

.p-dialog .p-dialog-content {
    padding: 1rem !important;
}

p.disc-list::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 50%;
    left: 15px;
    top: 25px;
}

// 
.upcoming-event .p-accordion-header .p-accordion-header-link {
    background: transparent !important;
    border: 0 !important;
    margin: 0;
}

.p-accordion.upcoming-event .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    color: #000;
}

.p-accordion.upcoming-event .p-accordion-content {
    background: none;
}

.program-detail-model{
    .p-dialog-title{
    text-align: left !important;
}
.p-dialog-header-icons{
    display: flex !important;
}
} 
.program-detail-table tr{
    font-weight: 700;
}
.class-schedule img{
    width: 100%;
}