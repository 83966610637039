.custom-calander {
    .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
        border: none;
    }

    .fc .fc-daygrid-day-top {
        flex-direction: column-reverse !important;
        text-align: left !important;
    }

    .fc.fc-theme-standard .fc-view-harness td {
        color: none !important;
        border-color: none !important;
        border: none;
        border-right: 1px solid #dee2e6 !important;
    }

    .fc.fc-theme-standard .fc-view-harness th {
        background: none !important;
        // border-color: none !important;
        // color: #495057;
        // text-align: center !important;
        border: none !important;
    }

    .fc-daygrid-day-frame {
        margin-top: 5px !important;
        border-top: 2px solid black !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background: none !important;
        // border-right: 1px solid  #dee2e6 !important ;
    }

    .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
        background-color: lightblue !important;
    }

    .eventshow-panel .p-overlaypanel-content {
        padding: 0 !important;
    }

    .fc-event-main {
        color: #111 !important;
    }

    .current-attendance .p-multiselect-trigger {
        display: none;
    }

    .group-dropdown .pi-chevron-down:before {
        color: #000;
    }

    .group-dropdown .p-dropdown-label::before {
        content: "";
        // background: url(../../icons/groupselect.png);
        position: absolute;
        width: 20px;
        height: 20px;
        background-size: contain;
        top: 15px;
        left: 10px;
    }

    .group-dropdown .p-dropdown-label {
        padding-left: 40px;
    }
    .fc-event-title {
        white-space: normal;
    }
    .fc-event-title.fc-sticky {
        font-size: 12px;
        font-weight: 600;
        padding: 7px;
    }

    .pointer-graph {
        width: 16px;
        height: 16px;
    }
}
