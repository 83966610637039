.p-inputnumber input {
  border: none;
  width: 100%;
}
.editor-width {
  height: 250px;
}
.react-tel-input .form-control {
  position: relative;
  margin: 0;
  width: 100% !important;
  height: 50px !important;
}
.user-profile {
  height: 265px;
  width: 90%;
}
.fit-cover{
  object-fit: cover;
  object-position: center;
}